// constants
import TitleDescriptionItem from "../title-description-item/TitleDescriptionItem";
import { TitleDescriptionItemType } from "../types";

export interface TitleDescriptionItemListProps {
    items: TitleDescriptionItemType[];
}

const TitleDescriptionItemList = ({ items = [] }: TitleDescriptionItemListProps) => {
    return (
        <div>
            {items.map((item) => (
                <TitleDescriptionItem key={item.id} item={item} />
            ))}
        </div>
    );
};

export default TitleDescriptionItemList;
