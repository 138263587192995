// hooks
import { sanitize } from "isomorphic-dompurify";

// constants
import { ParagraphType } from "../types";

import Typography from "@vahak/core-ui/dist/components/Typography";
import COLORS from "@vahak/core-ui/dist/constants/colors";

export interface ParagraphWrapperProps {
    paragraphs: ParagraphType[];
}

const ParagraphWrapper = ({ paragraphs }: ParagraphWrapperProps) => {
    return (
        <div style={{ marginTop: "1rem" }}>
            {paragraphs.map((para) => {
                return (
                    <Typography as="p" size="sm" mSize="s" color={COLORS.GREY_700}>
                        <span key={para.id} dangerouslySetInnerHTML={{ __html: sanitize(para.content) }} />
                    </Typography>
                );
            })}
        </div>
    );
};

export default ParagraphWrapper;
