import React from "react";
import Styles from "./RecentListSlider.module.scss";

interface RecentListSliderProps {
    children?: React.ReactNode;
    id?: string;
}

const RecentListSlider = ({ children, id }: RecentListSliderProps) => {
    return (
        <div className={Styles.main} id={id ? id + "_list" : ""}>
            {children}
        </div>
    );
};

export default RecentListSlider;
