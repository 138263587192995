import { ClientHookInfiniteQueryOptions, useVahakInfiniteQuery } from "@vahak/core/dist/hooks/useVahakQueryClient";

import useVahakHttpClient from "@vahak/core/dist/hooks/useVahakHttpClient";
import API from "@vahak/core/dist/_services/const";
import { LoadMarketType, LoadMarketParam } from "../../../../nextjs/services/load/useGetLoadMarketV2/type";
import queryNames from "../../queryNames";
import { generateQueryString } from "@vahak/core/dist/methods";

export const useGetLoadMarketInfiniteQueryV2 = (
    params: LoadMarketParam,
    options: ClientHookInfiniteQueryOptions<LoadMarketType, unknown, LoadMarketType["data"]>
) => {
    const {
        page,
        c_i,
        lorry_type_id,
        city_id,
        state_id,
        destination_city_id,
        destination_state_id,
        quantity_string,
        l,
        preloads,
        isAdmin,
        loadId,
        destination_place_id,
        source_place_id,
        lorry_type_counts,
        status
    } = params;
    const { GET } = useVahakHttpClient();
    function getLoads({ pageParam = 1 }) {
        const finalPreload = isAdmin ? preloads : preloads?.filter((val) => val != "agent_details");
        const payload = {
            page: pageParam,
            c_i,
            lorry_type_id,
            city_id,
            state_id,
            destination_city_id,
            destination_state_id,
            quantity_string,
            destination_place_id,
            source_place_id,
            lorry_type_counts,
            status,
            l,
            id: loadId,
            preloads: finalPreload
        };
        const urlString = generateQueryString(payload);
        return GET<LoadMarketType>(`${API.LOAD_MARKET_V2}${urlString}`);
    }

    return useVahakInfiniteQuery<LoadMarketType, unknown>([queryNames.getLoadMarketInfiniteQueryV2, params], getLoads, {
        getNextPageParam: (lastPage, allPages) => {
            if (lastPage.data?.page_number < lastPage.data?.total_pages) return lastPage.data?.page_number + 1;
            return undefined;
        },
        ...options,
        onSuccess: (data) => {
            let aggregatedData: LoadMarketType["data"] = {
                total_pages: 0,
                total_records: 0,
                page_number: 0,
                ...data?.pages?.[0],
                load_cards: data?.pages?.flatMap((page) => page?.data?.load_cards ?? [])
            };

            /**
             * Enclosing aggregatedData inside an array, just to fix the type script
             * as the onSuccess returns only aggregatedData[] - array type
             */
            options.onSuccess([aggregatedData]);
        },
        refetchOnWindowFocus: false
    });
};
