import React from "react";
//Styles
import Styles from "./FaqSection.module.scss";
import GenericTitleDescriptionWithParas from "../../generic-title-description-with-paras/GenericTitleDescriptionWithParas";

interface ContentSectionProps {
    content: { title: string; description: string }[];
}

const ContentSection = ({ content }: any) => {
    return (
        <div className={Styles.main}>
            {content?.map(({ title, description, contentList }: any, ix: number) => {
                return (
                    <div key={ix} className={Styles.questionContainer}>
                        <GenericTitleDescriptionWithParas
                            paragraphList={description}
                            titleDescriptionList={contentList}
                            heading={title}
                        />
                    </div>
                );
            })}
        </div>
    );
};

export default ContentSection;
