import API from "@vahak/core/dist/_services/const";
import { API_REQUEST_TYPE } from "@vahak/core/dist/constants/api";
import { BID_STATUS } from "@vahak/core/dist/constants/bidStatus";
import { LOAD_STATUS } from "@vahak/core/dist/constants/loadStatus";
import useVahakHttpClient from "@vahak/core/dist/hooks/useVahakHttpClient";
import {
    ClientHookInfiniteQueryOptions,
    ClientHookOptions,
    useVahakInfiniteQuery,
    useVahakMutationQuery,
    useVahakQuery
} from "@vahak/core/dist/hooks/useVahakQueryClient";
import { LoadAllDetails, LoadListApiResponse } from "./type";
import { QueryNames } from "@vahak/core/dist/_services/hooks/queryNames";
import { ApiKeyFilter } from "@vahak/core/dist/constants/api";
import { ApiErrorResponseType } from "@vahak/core/dist/custom-types/api-error-response-type";
import { filteredObjValues } from "@vahak/core-ui/dist/methods/object";

export type AllLoadApiPreload =
    | "agent_details"
    | "company_detail"
    | "company_detail"
    | "minimum_bid_info"
    | "bid_infos"
    | "bid_infos.bidder_info"
    | "bid_infos.tenderer_info"
    | "bid_infos.lorry_detail"
    | "bid_infos.tap_n_go_info"
    | "bid_infos.spot_load_info"
    | "bid_infos.payment_details"
    | "load_address"
    | "assigned_to_details"
    | "trip_current_location_details"
    | "ulip_info"
    | "gps_location_details";

interface IGetLoadParams {
    companyId?: number;
    loadStatus?: LOAD_STATUS[];
    bidStatus?: BID_STATUS[];
    vehicleType?: string[];
    loadPostTime?: string;
    loadId?: number[];
    bulkId?: number[];
    pageParam?: number;
    request_type?: API_REQUEST_TYPE;
    preload?: AllLoadApiPreload[];
    insuranceSupportedFilter?: ApiKeyFilter;
    isInsuredFilter?: ApiKeyFilter;
    cityIds?: number[];
}

interface IGetLoadPayload {
    company_id?: number;
    type?: string[]; // vehicle type
    status?: LOAD_STATUS[];
    loading_timestamp_string?: string;
    created_at_string?: string;
    page_number?: number;
    // request_type?: API_REQUEST_TYPE;
    id?: number[];
    bulk_id?: number[];
    lorry_history_fields?: string[];
    bid_status?: BID_STATUS[];
    preload?: AllLoadApiPreload[];
    is_insured_filter?: ApiKeyFilter;
    insurance_supported_filter?: ApiKeyFilter;
    city_id: IGetLoadParams["cityIds"];
}

export function useGetLoadAllDetailsMutateV2() {
    const { POST } = useVahakHttpClient();

    const getLoads = async (params: IGetLoadParams) => {
        const { bidStatus, bulkId, companyId, loadId, loadStatus, loadPostTime, vehicleType, pageParam, preload } =
            params;
        const payload: IGetLoadPayload = {
            page_number: pageParam,
            request_type: API_REQUEST_TYPE.ELASTIC_SEARCH,
            ...filteredObjValues({
                company_id: companyId,
                created_at_string: loadPostTime,
                id: loadId,
                bulk_id: bulkId,
                status: loadStatus?.map(String),
                bid_status: bidStatus?.map(String),
                type: vehicleType,
                preload: preload
            } as IGetLoadPayload)
        };

        return POST<IGetLoadPayload, LoadListApiResponse>(API.ALL_LOADS_V2, payload);
    };

    return useVahakMutationQuery<LoadListApiResponse, ApiErrorResponseType, IGetLoadParams>(
        QueryNames.userLoads.adminVerifyLoad,
        getLoads
    );
}

export const useGetLoadAllDetailsListV2 = (
    params: IGetLoadParams,
    options: ClientHookInfiniteQueryOptions<LoadListApiResponse, unknown, LoadAllDetails>
) => {
    const { POST } = useVahakHttpClient();
    function getLoadsList({ pageParam = 1 }) {
        const {
            bidStatus,
            bulkId,
            companyId,
            loadId,
            loadStatus,
            loadPostTime,
            vehicleType,
            preload,
            insuranceSupportedFilter,
            isInsuredFilter
        } = params;
        const payload: IGetLoadPayload = {
            page_number: pageParam,
            request_type: API_REQUEST_TYPE.ELASTIC_SEARCH,
            ...filteredObjValues({
                company_id: companyId,
                created_at_string: loadPostTime,
                id: loadId,
                bulk_id: bulkId,
                status: loadStatus?.map(String),
                bid_status: bidStatus?.map(String),
                type: vehicleType,
                preload: preload,
                insurance_supported_filter: insuranceSupportedFilter,
                is_insured_filter: isInsuredFilter,
                city_id: params?.cityIds
            } as IGetLoadPayload)
        };
        return POST<IGetLoadPayload, LoadListApiResponse>(API.ALL_LOADS_V2, payload);
    }

    return useVahakInfiniteQuery<LoadListApiResponse, unknown>([QueryNames.getLoadAllData, params], getLoadsList, {
        getNextPageParam: (lastPage, allPages) => {
            if (lastPage.data && lastPage?.data?.page_number < lastPage?.data?.total_pages)
                return lastPage?.data?.page_number + 1;
            return undefined;
        },
        ...options,
        onSuccess: (data) => {
            let aggregatedData: LoadAllDetails[] = [];
            data.pages.forEach((data) => {
                aggregatedData = [...aggregatedData, ...(data?.data?.load_cards ?? [])];
            });
            options.onSuccess(aggregatedData);
        }
    });
};

interface IGetLoadQueryParams extends Omit<IGetLoadParams, "loadId"> {
    loadId: number;
}
export const useGetLoadDetailsQueryV2 = (
    params: IGetLoadQueryParams,
    options: ClientHookOptions<LoadListApiResponse, unknown, LoadAllDetails | undefined>
) => {
    const { POST } = useVahakHttpClient();
    const { bidStatus, bulkId, companyId, loadId, loadStatus, loadPostTime, vehicleType, preload } = params;

    const getLoad = async ({ pageParam = 1 }) => {
        const payload: IGetLoadPayload = {
            page_number: pageParam,
            // request_type: API_REQUEST_TYPE.ELASTIC_SEARCH,
            ...filteredObjValues({
                company_id: companyId,
                created_at_string: loadPostTime,
                id: loadId ? [loadId] : undefined,
                bulk_id: bulkId,
                status: loadStatus?.map(String),
                bid_status: bidStatus?.map(String),
                type: vehicleType,
                preload: preload
            } as IGetLoadPayload)
        };

        return POST<IGetLoadPayload, LoadListApiResponse>(API.ALL_LOADS_V2, payload);
    };
    return useVahakQuery<LoadListApiResponse, unknown, LoadAllDetails | undefined>(
        [QueryNames.getLoadAllData, ...Object.keys(params)],
        getLoad,
        {
            ...options,
            select: (data) => data.data?.load_cards?.[0]
        }
    );
};
