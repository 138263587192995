import React, { useState } from "react";
// Components
import FieldWrapper from "../../../../../posting-form/common/field-wrapper/FieldWrapper";
import FieldHeading from "../../../../../posting-form/common/field-heading/FieldHeading";
import Typography from "@vahak/core-ui/dist/components/Typography";
import RecentListSlider from "../../../../../posting-form/common/recent-list-slider/RecentListSlider";
import RecentRoute from "../../../../../posting-form/common/recent-route/RecentRoute";
// Constants
import { MarketPlaceType, NoSuccessErrorHandler } from "@vahak/core/dist/constants";
import { FullScreenLocationInputField, FullScreenLocationInputProps } from "../FullScreenLocationInput";
// Methods
// Hooks
import {
    PopularLaneCityInfo,
    useGetMostUsedLane,
    useGetPopularLane
} from "../../../../../../services/posting/useGetPopularlane";
import {
    AggregatedLoadEnquiryType,
    useGetLoadEnquiryCardDetails
} from "../../../../../../services/load/useGetLoadEnquiryCardDetails";
import {
    AggregatedLorryEnquiryType,
    useGetLorryEnquiryCardDetails
} from "../../../../../../services/lorry/useGetLorryEnquiryCardDetails";
// Assets

type EnquiryCard = AggregatedLoadEnquiryType | AggregatedLorryEnquiryType;

interface SuggestionProps
    extends Pick<FullScreenLocationInputProps, "companyId" | "marketType" | "filters" | "onApplyFilters"> {
    showSrcLaneSuggestion?: boolean;
    showDestLaneSuggestion?: boolean;
    shiftInputFocus: (target: FullScreenLocationInputField) => void;
    removeInputFocus?: () => void;
}

const Suggestion = ({
    companyId,
    marketType,
    filters,
    onApplyFilters,
    showSrcLaneSuggestion,
    showDestLaneSuggestion,
    shiftInputFocus,
    removeInputFocus
}: SuggestionProps) => {
    const [enquiryCard, setEnquiryCard] = useState<EnquiryCard[]>();
    const [suggestedSrcLane, setSuggestedSrcLane] = useState<PopularLaneCityInfo>();
    const [suggestedDestLane, setSuggestedDestLane] = useState<PopularLaneCityInfo>();

    const isLoadMkt = marketType === MarketPlaceType.LOAD;
    const isLorryMkt = marketType === MarketPlaceType.LORRY;

    useGetLoadEnquiryCardDetails(
        {
            companyId: Number(companyId)
        },
        {
            ...NoSuccessErrorHandler,
            onSuccess: setEnquiryCard,
            enabled: !!companyId && isLoadMkt,
            refetchOnWindowFocus: false,
            cacheTime: Infinity
        }
    );

    useGetLorryEnquiryCardDetails(
        {
            companyId: Number(companyId)
        },
        {
            ...NoSuccessErrorHandler,
            onSuccess: setEnquiryCard,
            enabled: !!companyId && isLorryMkt,
            refetchOnWindowFocus: false,
            cacheTime: Infinity
        }
    );

    useGetMostUsedLane(
        {
            ...(isLoadMkt && {
                preloads: ["load_sources"]
            }),
            ...(isLorryMkt && {
                preloads: ["lorry_sources"]
            })
        },
        {
            ...NoSuccessErrorHandler,
            onSuccess: (data) => {
                if (isLoadMkt) {
                    setSuggestedSrcLane(data?.load_sources_destination?.source_cities?.[0]);
                } else if (isLorryMkt) {
                    setSuggestedSrcLane(data?.lorry_source_destination?.source_cities?.[0]);
                }
            },
            refetchOnWindowFocus: false,
            cacheTime: Infinity,
            enabled: showSrcLaneSuggestion
        }
    );

    useGetPopularLane(
        {
            placeId: filters?.srcPlace?.place_id ?? "",
            ...(isLoadMkt && {
                preloads: ["load_destinations"]
            }),
            ...(isLorryMkt && {
                preloads: ["lorry_destinations"]
            })
        },
        {
            ...NoSuccessErrorHandler,
            enabled: showDestLaneSuggestion && !!filters?.srcPlace?.place_id,
            onSuccess: (data) => {
                if (isLoadMkt) {
                    setSuggestedDestLane(data?.load_sources_destination?.destination_cities?.[0]);
                } else if (isLorryMkt) {
                    setSuggestedDestLane(data?.lorry_source_destination?.destination_cities?.[0]);
                }
            },
            refetchOnWindowFocus: false,
            cacheTime: Infinity
        }
    );

    return (
        <div>
            <FieldWrapper>
                {showSrcLaneSuggestion && !!suggestedSrcLane?.city_data?.length ? (
                    <>
                        <FieldHeading>
                            <Typography mSize="sm" weight="semibold">
                                Most used loading point
                            </Typography>
                        </FieldHeading>
                        <RecentListSlider id="popular">
                            {suggestedSrcLane?.city_data?.map((lane, ix) => {
                                return (
                                    <RecentRoute
                                        onClick={() => {
                                            onApplyFilters?.({
                                                ...filters,
                                                srcPlace: {
                                                    city: lane?.city_name,
                                                    place_id: lane?.place_id
                                                },
                                                srcText: `${lane?.city_name}`
                                            });
                                            shiftInputFocus("dest");
                                        }}
                                        dest={lane.city_name}
                                        id={"popular-" + ix}
                                        key={lane.place_id}
                                        showArrow={false}
                                    />
                                );
                            })}
                        </RecentListSlider>
                    </>
                ) : null}
            </FieldWrapper>
            <FieldWrapper>
                {showDestLaneSuggestion && !!suggestedDestLane?.city_data?.length ? (
                    <>
                        <FieldHeading>
                            <Typography mSize="sm" weight="semibold">
                                Most used loading point from {suggestedDestLane?.city_name ?? ""}
                            </Typography>
                        </FieldHeading>
                        <RecentListSlider id="popular">
                            {suggestedDestLane?.city_data?.map((lane, ix) => {
                                return (
                                    <RecentRoute
                                        onClick={() => {
                                            onApplyFilters?.({
                                                ...filters,
                                                destPlace: {
                                                    city: lane?.city_name,
                                                    place_id: lane?.place_id
                                                },
                                                destText: `${lane?.city_name}`
                                            });
                                            removeInputFocus?.();
                                        }}
                                        dest={lane.city_name}
                                        id={"popular-" + ix}
                                        key={lane.place_id}
                                        showArrow={false}
                                    />
                                );
                            })}
                        </RecentListSlider>
                    </>
                ) : null}
            </FieldWrapper>
            <FieldWrapper>
                {!!enquiryCard?.length ? (
                    <>
                        <FieldHeading>
                            <Typography mSize="sm" weight="semibold">
                                Recently Searched
                            </Typography>
                        </FieldHeading>
                        <RecentListSlider id="enquiry">
                            {enquiryCard?.map((enquiry, ix) => (
                                <RecentRoute
                                    onClick={() => {
                                        onApplyFilters?.({
                                            ...filters,
                                            srcPlace: {
                                                city: enquiry?.source_city_name,
                                                state: enquiry?.source_state_name,
                                                place_id: enquiry?.source_place_id
                                            },
                                            srcText: `${enquiry?.source_city_name}`,
                                            destPlace: {
                                                city: enquiry?.destination_city_name,
                                                state: enquiry?.destination_state_name,
                                                place_id: enquiry?.destination_place_id
                                            },
                                            destText: `${enquiry?.destination_city_name}`
                                        });
                                    }}
                                    dest={enquiry.destination_city_name}
                                    src={enquiry?.source_city_name}
                                    id={"enquiry-" + ix}
                                    key={ix}
                                    showArrow={false}
                                    lorryType={enquiry.lorry_type_id?.[0]}
                                    showBulletsDesc={false}
                                    showSearchedLorryType={true}
                                />
                            ))}
                        </RecentListSlider>
                    </>
                ) : null}
            </FieldWrapper>
        </div>
    );
};

export default Suggestion;
