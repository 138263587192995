// components
import ParagraphWrapper from "./intro-paras/ParagraphWrapper";
import TitleDescriptionItemList from "./title-description-item-list/TitleDescriptionItemList";

// types
import { ParagraphType, TitleDescriptionItemType } from "./types";
import Typography from "@vahak/core-ui/dist/components/Typography";

export interface GenericTitleDescriptionWithParasProps {
    paragraphList?: ParagraphType[];
    titleDescriptionList: TitleDescriptionItemType[];
    heading: string;
}

const GenericTitleDescriptionWithParas = ({
    paragraphList,
    titleDescriptionList,
    heading
}: GenericTitleDescriptionWithParasProps) => {
    return (
        <div>
            <Typography as="h2" size="m" mSize="sm">
                {heading}
            </Typography>
            {paragraphList ? <ParagraphWrapper paragraphs={paragraphList} /> : <></>}
            <TitleDescriptionItemList items={titleDescriptionList} />
        </div>
    );
};

export default GenericTitleDescriptionWithParas;
