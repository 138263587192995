import AppImg from "../../../utils/AppImg";
import React from "react";
import { getImageNameFromURL } from "../../../../utils/getImageNameFromURL";
import Styles from "./NoActiveLoadsLorries.module.scss";
import Button, { ButtonOnClickType } from "@vahak/core-ui/dist/components/Button";

interface Props {
    title: string;
    description?: string;
    image?: string;
    width?: string;
    height?: string;
    unoptimized?: boolean;
    ctaText?: string;
    ctaAction?: ButtonOnClickType;
}

const NoActiveLoadsLorries = ({ title, description, image, width, height, unoptimized, ctaText, ctaAction }: Props) => {
    return (
        <div className={Styles.main}>
            <div className={Styles.imageBox} style={{ width: `${width}px`, height: `${height}px` }}>
                {image && <AppImg src={image} alt={title} width={width} height={height} unoptimized={unoptimized} />}
            </div>
            <h2>{title}</h2>
            {!!description && <p>{description}</p>}
            {!!ctaText && (
                <p>
                    <Button onClick={ctaAction} isRounded width={200}>
                        {ctaText}
                    </Button>
                </p>
            )}
        </div>
    );
};

export default NoActiveLoadsLorries;
