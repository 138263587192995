export const getMktContentByCity = (IN_CITY_TEXT: string, isLoad: boolean) => {
    const loadLorryText = isLoad ? "load" : "lorry";
    const titleH1Desc = `Vahak is India\’s largest and most trusted online transport services company ${IN_CITY_TEXT}. We at Vahak are committed to improving the Indian transportation services industry through a technology-driven approach. Through our platform, we are helping reduce the current demand-supply gap in the transport industry by providing instant online ${loadLorryText} ${IN_CITY_TEXT}.
    Vahak hosts India’s largest ${loadLorryText} marketplace, allowing users to book transport services instantly without paying commission charges. Through the Vahak Transport Marketplace, users can bid & book loads or lorries from across India.`;

    const content = [
        {
            title: `Why Choose Vahak Marketplace Portal ${IN_CITY_TEXT}`,
            description: [
                {
                    id: 0,
                    content: `With Vahak, explore the best platform for loads and vehicles ${IN_CITY_TEXT}. Embark on a revolutionary transportation experience with Vahak in now, a marketplace tailored to fulfil all of your logistical requirements.`
                }
            ],
            contentList: [
                {
                    id: 0,
                    title: "Registered Transporters",
                    description:
                        "With Vahak, you can feel secure knowing that each transporter has their Aadhar and GST validated. Your safety and security are our top priorities, and Vahak ensures you work with reliable and respectable experts."
                },
                {
                    id: 1,
                    title: "Shippers' Profiles",
                    description:
                        "Get access to extensive shipper profiles, enabling you to make knowledgeable selections. Such profiles offer crucial information that will assist you in selecting the ideal partners for your logistical needs."
                },
                {
                    id: 2,
                    title: "Reliable Transportation",
                    description:
                        "Make use of Vahak's trustworthy and efficient transport services to maximise your logistics. Your items will be transported quickly and safely to their destination thanks to the efficiency of the platform."
                },
                {
                    id: 3,
                    title: "Extensive Network",
                    description:
                        "Take advantage of our vast network of shippers and carriers throughout. Vahak gives you access to a large community, thereby improving the likelihood of discovering the ideal travel options."
                },
                {
                    id: 4,
                    title: "Cost-effective Pricing",
                    description: `Take advantage of the affordable pricing strategy, which is intended to give you the most value for your money. Vahak enables you to control your transportation expenses efficiently and transparently.`
                }
            ]
        }
    ];
    const faqList = [
        {
            question: `How do I use the Vahak Marketplace to find ${loadLorryText} ${IN_CITY_TEXT}?`,
            answer: `While searching over the Vahak portal, opt for the find ${loadLorryText} option ${IN_CITY_TEXT}. Find ${loadLorryText} as per your choice.`
        },
        {
            question: `How can I filter ${loadLorryText} listings based on my preferences?`,
            answer: `Utilise Vahak's comprehensive search parameters to refine your ${loadLorryText} of listings. To swiftly identify the best choices, you may select by cargo type, weight, destination, and other factors.`
        },
        {
            question: `How do I submit a bid for a load on the Vahak Marketplace?`,
            answer: `Select the load you’re interested in, click on "Submit Bid," and enter your bid amount along with any relevant details. Confirm your bid to send it to the load provider.`
        },
        {
            question: "Can I track the status of my bid submissions?",
            answer: "To monitor the status of my bid submissions, you can simply search for the 'My Bids' section. Here, you will know if your bid was approved or likely to be approved."
        },
        {
            question: "Is there a messaging system available to communicate with load providers?",
            answer: "Vahak offers a built-in messaging system that allows you to communicate directly with load providers."
        },
        {
            question: "What payment methods are accepted for load transactions?",
            answer: "Vahak supports transactions through digital wallets, credit/debit cards, and bank transfers."
        }
    ];
    return {
        titleH1Desc,
        content,
        faqList
    };
};
