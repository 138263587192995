export interface FaqSchemaOrgParams {
    quesAns: { question: string; answer: string; descList?: string[] }[];
}

export function getFaqSchemaOrgJson({ quesAns }: FaqSchemaOrgParams) {
    const mainEntityArr = JSON.stringify(
        quesAns.map((q) => {
            const answerText = q.descList
                ? `${q.answer}<ul>${q.descList.map((item) => `<li>${item}</li>`).join("")}</ul>`
                : q.answer;

            return {
                "@type": "Question",
                name: q.question,
                acceptedAnswer: {
                    "@type": "Answer",
                    text: answerText
                }
            };
        })
    );
    return `
                    {
                        "@context": "https://schema.org",
                        "@type": "FAQPage",
                        "mainEntity": ${mainEntityArr}
                    }
            `;
}
