import API from "@vahak/core/dist/_services/const";
import { ClientHookInfiniteQueryOptions, useVahakInfiniteQuery } from "@vahak/core/dist/hooks/useVahakQueryClient";
import useVahakHttpClient from "@vahak/core/dist/hooks/useVahakHttpClient";

/**
 * Get All tagged users of tagged TSO with filters
 */
interface GetLoadsEnquiryCardParams {
    preloads?: "city_details";
    companyId: number;
}

export interface LoadEnquiryType {
    source_city_id: number[];
    source_state_id: number[];
    destination_city_id: number[];
    destination_state_id: number[];
    id: string;
    favourite: boolean;
    company_id: number;
    match_count: number;
    dirty: boolean;
    lorry_type_id?: Array<number>;
}
export interface AggregatedLoadEnquiryType {
    company_id: number;
    destination_city_id: number;
    destination_city_name: string;
    destination_place_id: string;
    destination_state_id: number;
    destination_state_name: string;
    dirty: boolean;
    favourite: boolean;
    id: string;
    match_count: number;
    source_city_id: number;
    source_city_name: string;
    source_place_id: string;
    source_state_id: number;
    source_state_name: string;
    lorry_type_id?: Array<number>;
}

export interface GetLoadEnquiryCardListResponse {
    data: {
        filter: LoadEnquiryType[];
        load_filter: LoadEnquiryType[];
        lorry_filter: {}[];
        total_pages: number;
        total_records: number;
        page_number: number;
        preloads: {
            city_details: [
                {
                    city_place_id: string;
                    city_id: number;
                    city_name: string;
                    state_id: number;
                    state_name: string;
                }
            ];
        };
    };
}

export const useGetLoadEnquiryCardDetails = (
    params: GetLoadsEnquiryCardParams,
    options: ClientHookInfiniteQueryOptions<GetLoadEnquiryCardListResponse, unknown, AggregatedLoadEnquiryType>
) => {
    const { GET } = useVahakHttpClient();

    function getCSUsers({ pageParam = 1 }) {
        const { companyId, preloads } = params;
        return GET<GetLoadEnquiryCardListResponse>(
            `${API.LOADS_ENQUIRY_CARD}?c_i=${companyId}&preloads=city_details&`,
            {
                page: pageParam
            }
        );
    }

    return useVahakInfiniteQuery<GetLoadEnquiryCardListResponse, unknown>(["load-enquiry-card"], getCSUsers, {
        getNextPageParam: (lastPage, allPages) => {
            if (lastPage.data?.page_number < lastPage.data?.total_pages) return lastPage.data?.page_number + 1;
            return undefined;
        },
        ...options,
        onSuccess: (data) => {
            let aggregatedData: AggregatedLoadEnquiryType[] = [];
            if (data.pages[0].data.load_filter.length) {
                const cityDetailsArr = data.pages[0].data?.preloads.city_details;
                const loadFilterDetailsArr = data.pages[0].data.load_filter;

                const cityDetailsMap: {
                    city_id: number;
                    city_name: string;
                    state_id: number;
                    state_name: string;
                    place_id: string;
                }[] = [];
                cityDetailsArr?.forEach((city) => {
                    cityDetailsMap[city.city_id] = {
                        city_id: city.city_id,
                        city_name: city.city_name,
                        state_id: city.state_id,
                        state_name: city.state_name,
                        place_id: city.city_place_id
                    };
                });
                // Iterate through loadDetails and generate aggregated data
                const aggregatedDataNew = loadFilterDetailsArr?.map((load) => {
                    const sourceCityId = load.source_city_id[0];
                    const destinationCityId = load.destination_city_id[0];
                    return {
                        id: load.id,
                        favourite: load.favourite,
                        company_id: load.company_id,
                        match_count: load.match_count,
                        dirty: load.dirty,
                        source_place_id: cityDetailsMap[sourceCityId].place_id,
                        source_city_id: sourceCityId,
                        source_city_name: cityDetailsMap[sourceCityId].city_name,
                        source_state_id: cityDetailsMap[sourceCityId].state_id,
                        source_state_name: cityDetailsMap[sourceCityId].state_name,
                        destination_place_id: cityDetailsMap[destinationCityId].place_id,
                        destination_city_id: destinationCityId,
                        destination_city_name: cityDetailsMap[destinationCityId].city_name,
                        destination_state_id: cityDetailsMap[destinationCityId].state_id,
                        destination_state_name: cityDetailsMap[destinationCityId].state_name,
                        lorry_type_id: load.lorry_type_id
                    };
                });

                aggregatedData = aggregatedDataNew;
            }
            options.onSuccess(aggregatedData);
        }
    });
};

interface GetAllCustomersEnquiryCardParams {
    preloads?: "city_details";
    filterType: "load_filters" | "lorry_filters";
}

export interface AllCustomersEnquiryCardType {
    company_id: number;
    name: string;
    logo: string;
    is_gst_verified: boolean;
    is_aadhaar_verified: boolean;
    is_pan_verified: boolean;
    is_payment_linked: boolean;
    is_member: boolean;
    source_city: string;
    source_city_id: number;
    source_city_place_id: string;
    source_state: string;
    source_state_id: number;
    destination_city: string;
    destination_city_id: number;
    destination_city_place_id: string;
    destination_state: string;
    destination_state_id: number;
    available_loads: boolean;
    available_loads_count: number;
    lorry_type_id?: Array<number>;
}

export interface GetAllCustomersEnquiryCardListResponse {
    data: {
        tagged_company_enquiry_card: AllCustomersEnquiryCardType[];
        page_number: number;
        total_pages: number;
        total_records: number;
    };
}

export const useGetAllCustomersEnquiryCardDetails = (
    params: GetAllCustomersEnquiryCardParams,
    options: ClientHookInfiniteQueryOptions<
        GetAllCustomersEnquiryCardListResponse,
        unknown,
        AllCustomersEnquiryCardType
    >
) => {
    const { GET } = useVahakHttpClient();

    function getEnquiryCard({ pageParam = 1 }) {
        const { preloads, filterType } = params;
        return GET<GetAllCustomersEnquiryCardListResponse>(`${API.ALL_CUSTOMERS_ENQUIRY_CARD}`, {
            page: pageParam,
            filter_type: filterType
        });
    }

    return useVahakInfiniteQuery<GetAllCustomersEnquiryCardListResponse, unknown>(
        ["all-customers-enquiry-card"],
        getEnquiryCard,
        {
            getNextPageParam: (lastPage, allPages) => {
                //No infinity query support
                if (lastPage.data?.page_number < lastPage.data?.total_pages) return lastPage.data?.page_number + 1;
                return undefined;
            },
            ...options,
            onSuccess: (data) => {
                let aggregatedData: AllCustomersEnquiryCardType[] = [];
                data.pages.forEach((data) => {
                    aggregatedData = [...aggregatedData, ...(data?.data.tagged_company_enquiry_card ?? [])];
                });
                options.onSuccess(aggregatedData);
            }
        }
    );
};
