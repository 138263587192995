interface BreadcrumbsProps {
    mktType?: string;
    city?: string;
}
export function breadcrumbsConstants({ mktType, city }: BreadcrumbsProps) {
    const breadcrumbs = {
        onlineLoadBooking: `[
            {
                "@type": "ListItem", 
                "position": 1, 
                "name": "Home",
                "item": "https://www.vahak.in/"  
            },
            {
                "@type": "ListItem", 
                "position": 2, 
                "name": "Load Booking",
                "item": "https://www.vahak.in/online-load-booking"  
            }
        ]`,
        onlineLorryBooking: `[
            {
                "@type": "ListItem", 
                "position": 1, 
                "name": "Home",
                "item": "https://www.vahak.in/"  
            },
            {
                "@type": "ListItem", 
                "position": 2, 
                "name": "Truck Booking",
                "item": "https://www.vahak.in/online-truck-booking"  
            }
        ]`,
        growNetwork: `[
            {
                "@type": "ListItem", 
                "position": 1, 
                "name": "Home",
                "item": "https://www.vahak.in/"  
            },
            {
                "@type": "ListItem", 
                "position": 2, 
                "name": "Grow Transport Buisness",
                "item": "https://www.vahak.in/grow-network"  
            }
        ]`,
        logisticsServices: `[
            {
                "@type": "ListItem", 
                "position": 1, 
                "name": "Home",
                "item": "https://www.vahak.in/"  
            },
            {
                "@type": "ListItem", 
                "position": 2, 
                "name": "Logistics Services",
                "item": "https://www.vahak.in/logistics-services"  
            }
        ]`,
        fullTruckLoad: `[
            {
                "@type": "ListItem", 
                "position": 1, 
                "name": "Home",
                "item": "https://www.vahak.in/"  
            },
            {
                "@type": "ListItem", 
                "position": 2, 
                "name": "Full Truck Load",
                "item": "https://www.vahak.in/full-truck-load"  
            }
        ]`,
        partTruckLoad: `[
            {
                "@type": "ListItem", 
                "position": 1, 
                "name": "Home",
                "item": "https://www.vahak.in/"  
            },
            {
                "@type": "ListItem", 
                "position": 2, 
                "name": "Part Load Booking",
                "item": "https://www.vahak.in/part-load-booking"  
            }
        ]`,
        marketplace: `[
            {
                "@type": "ListItem", 
                "position": 1, 
                "name": "Home",
                "item": "https://www.vahak.in/"  
            },
            {
                "@type": "ListItem", 
                "position": 2, 
                "name": "${mktType} Marketplace",
                "item": "https://www.vahak.in/marketplace/${mktType}/"  
            }  
        ]`,
        transportDirectory: `[
            {
                "@type": "ListItem", 
                "position": 1, 
                "name": "Home",
                "item": "https://www.vahak.in/"  
            },
            {
                "@type": "ListItem", 
                "position": 2, 
                "name": "Transporters",
                "item": "https://www.vahak.in/transporters"  
            }
        ]`,
        transportDirectoryLocation: `[
            {
                "@type": "ListItem", 
                "position": 1, 
                "name": "Home",
                "item": "https://www.vahak.in/"  
            },
            {
                "@type": "ListItem", 
                "position": 2, 
                "name": "Transporters",
                "item": "https://www.vahak.in/transporters"  
            },
            {
                "@type": "ListItem", 
                "position": 3, 
                "name": "${city}",
                "item": "https://www.vahak.in/transporters/${city}"  
            }
        ]`,
        cityPages: `[
            {
                "@type": "ListItem", 
                "position": 1, 
                "name": "Home",
                "item": "https://www.vahak.in/"  
            },
            {
                "@type": "ListItem", 
                "position": 2, 
                "name": ${city},
                "item": "https://www.vahak.in/city/${city}"  
            }
        ]`
    };
    return breadcrumbs;
}
