import React from "react";
import classnames from "classnames";
import Styles from "./MarketplacePostingNudgeCard.module.scss";
import Typography from "@vahak/core-ui/dist/components/Typography";
import Flex, { VerticalFlex } from "@vahak/core-ui/dist/layout/Flex";
import Button from "@vahak/core-ui/dist/components/Button";
import useValidateUserStatus from "../../../hooks/useValidateUserStatus";
import { POSTING_FORM_MODAL_ORIGINATION_PAGE, useLoadLorryPosting } from "../../../context/load-lorry-posting";
import { LOAD_CREATION_FORM_IDS, LORRY_CREATION_FORM_IDS } from "../../posting/helpers/constants";
import COLORS from "@vahak/core-ui/dist/constants/colors";
import AppImg from "../../utils/AppImg";
import useMediaQuery from "@vahak/core-ui/dist/hooks/useMediaQuery";
import { useEventTrackerService } from "@vahak/core/dist/_services/hooks/useEventTrackerService";
import { GA4EventNames } from "@vahak/core/dist/constants";

interface MarketplacePostingNudgeCardProps {
    type: "load" | "lorry";
}

const MarketplacePostingNudgeCard = ({ type }: MarketplacePostingNudgeCardProps) => {
    const { ValidateLoadPosting, ValidateLorryPosting } = useValidateUserStatus();
    const loadLorryPostingConfig = useLoadLorryPosting();
    const { sendGAandMoEngageEvent } = useEventTrackerService();

    const isMobileScreen = useMediaQuery({ queryType: "mobile" });
    return (
        <Flex
            className={classnames(Styles.main, Styles[type])}
            gap={20}
            flexDirection={isMobileScreen ? "row-reverse" : "column"}
        >
            <AppImg
                src={type === "load" ? "/load-icons/v1/Load.png" : `/lorry-icons/v3/Container.png`}
                width={type === "load" ? 70 : 156}
                height={type === "load" ? 77 : 74}
                unoptimized
            />
            <Flex flexDirection="column" gap={20}>
                <div className={Styles.textWrapper}>
                    <Typography color={COLORS.WHITE} weight="medium" align="center" mSize="sm">
                        {type === "load"
                            ? "Post your load to get matching lorries"
                            : "Add your lorry to get matching loads"}
                    </Typography>
                </div>
                <Button
                    isRounded
                    type="button"
                    color="tertiary"
                    blockBtn
                    onClick={() => {
                        if (type === "load") {
                            ValidateLoadPosting(() => {
                                setTimeout(() => {
                                    sendGAandMoEngageEvent({
                                        name: GA4EventNames.MARKET_PLACE.post_load_button_click,
                                        data: {
                                            source_screen: "marketplace"
                                        }
                                    });
                                    loadLorryPostingConfig?.openLoadPostingModal(LOAD_CREATION_FORM_IDS.LOAD_INFO);
                                    loadLorryPostingConfig?.updateLoadLorryPostingData({
                                        isModalOpen: true,
                                        category: "post",
                                        originationPage: POSTING_FORM_MODAL_ORIGINATION_PAGE.MARKETPLACE,
                                        type: "load"
                                    });
                                }, 1000);
                            });
                        } else {
                            ValidateLorryPosting(() => {
                                setTimeout(() => {
                                    sendGAandMoEngageEvent({
                                        name: GA4EventNames.MARKET_PLACE.add_lorry_attach_lorry_btn,
                                        data: {
                                            source_screen: "marketplace"
                                        }
                                    });
                                    loadLorryPostingConfig?.updateLoadLorryPostingData({
                                        isModalOpen: true,
                                        category: "post",
                                        originationPage: POSTING_FORM_MODAL_ORIGINATION_PAGE.MARKETPLACE,
                                        type: "lorry"
                                    });
                                    loadLorryPostingConfig?.openLorryPostingModal(
                                        LORRY_CREATION_FORM_IDS.VEHICLE_NUMBER
                                    );
                                }, 1000);
                            });
                        }
                    }}
                >
                    {type === "load" ? "Post Load" : "Post Lorry"}
                </Button>
            </Flex>
        </Flex>
    );
};

export default MarketplacePostingNudgeCard;
