import { ClientHookOptions, useVahakMutationQuery, useVahakQuery } from "@vahak/core/dist/hooks/useVahakQueryClient";

import API from "@vahak/core/dist/_services/const";
import { QueryNames } from "@vahak/core/dist/_services/hooks/queryNames";
import useVahakHttpClient from "@vahak/core/dist/hooks/useVahakHttpClient";
import { generateQueryString } from "@vahak/core/dist/methods/generateQueryString";
import { PlaceSearchResult } from "@vahak/core/dist/custom-types/location-search";
import { LocationSearchParamsOption } from "@vahak/core/dist/_services/city-search.service";
import { useDebounce } from "@vahak/core/dist/hooks/useDebounce";

interface PlaceSearchQueryResponse {
    data: PlaceSearchResult[];
}

interface PlaceSearchQueryRequest {
    query: string;
    debounceDelay?: number;
    option?: LocationSearchParamsOption;
}

export const usePlaceSearchQuery = (
    { query: _query, option, debounceDelay = 1000 }: PlaceSearchQueryRequest,
    options: ClientHookOptions<PlaceSearchQueryResponse, unknown, PlaceSearchQueryResponse["data"]>
) => {
    const { GET } = useVahakHttpClient();
    const debouncedQuery = useDebounce(_query, debounceDelay);

    const method = () => {
        const { source, allowState: is_state_allowed, tags = [] } = option ?? {};

        const payload = {
            tag: tags,
            source,
            is_state_allowed
        };

        const urlString = generateQueryString(payload);
        if (!debouncedQuery || debouncedQuery.length < 2) {
            return {
                data: []
            } as PlaceSearchQueryResponse;
        }
        return GET<PlaceSearchQueryResponse>(`${API.PLACES_API_SEARCH}${debouncedQuery}&${urlString}`, {});
    };

    return useVahakQuery<PlaceSearchQueryResponse, unknown, PlaceSearchQueryResponse["data"]>(
        [QueryNames.searchLocation, option, debouncedQuery],
        method,
        {
            ...options,
            select: (data) => {
                return data?.data ?? [];
            }
        }
    );
};

export const usePlaceSearchMutation = () => {
    const { GET } = useVahakHttpClient();

    const method = (params: PlaceSearchQueryRequest) => {
        const { query, option } = params;
        const { source, allowState: is_state_allowed, tags: tag = [] } = option ?? {};

        const payload = {
            tag,
            source,
            is_state_allowed
        };

        const urlString = generateQueryString(payload);
        if (!query || query.length < 2) {
            return new Promise((resolve) => {
                return resolve({
                    data: []
                } as PlaceSearchQueryResponse);
            }) as any;
        }
        return GET<PlaceSearchQueryResponse>(`${API.PLACES_API_SEARCH}${query}&${urlString}`);
    };

    return useVahakMutationQuery<PlaceSearchQueryResponse, unknown, PlaceSearchQueryRequest>(
        [QueryNames.searchLocation],
        method
    );
};
