import React from "react";

//Components
import Typography from "@vahak/core-ui/dist/components/Typography/Typography";

//Styles
import Styles from "./FaqSection.module.scss";
import { sanitize } from "isomorphic-dompurify";
import { Colors } from "@vahak/core-ui/dist/constants";

interface FaqSectionProps {
    faqList: { question: string; answer: string }[];
}

const FaqSection = ({ faqList }: FaqSectionProps) => {
    return (
        <div className={Styles.main}>
            <Typography size="m" mSize="sm" as="h2" weight="bold">
                Frequently Asked Questions
            </Typography>
            <ol>
                {faqList?.map(({ question, answer, descList }: any, ix) => {
                    return (
                        <li key={ix} className={Styles.questionContainer}>
                            <section>
                                <Typography as="h3" size="sm" mSize="s" lineHeight="1.5rem">
                                    {question}
                                </Typography>
                                <Typography as="span" size="sm" mSize="s" color={Colors.GREY_700} lineHeight="1.5rem">
                                    <span dangerouslySetInnerHTML={{ __html: sanitize(answer) }}></span>
                                    <ul className={Styles.ansDescList}>
                                        {descList?.map((l: string, ix: number) => (
                                            <li key={ix}>{l}</li>
                                        ))}
                                    </ul>
                                </Typography>
                            </section>
                        </li>
                    );
                })}
            </ol>
        </div>
    );
};

export default FaqSection;
