import React, { ReactNode } from "react";
import classnames from "classnames";
import dynamic from "next/dynamic";

//component
import Button from "@vahak/core-ui/dist/components/Button";
import Typography from "@vahak/core-ui/dist/components/Typography";
import Flex from "@vahak/core-ui/dist/layout/Flex";

const DotLoader = dynamic(() => import("@vahak/core-ui/dist/components/DotLoader"));

//Style
import Styles from "./ListContainer.module.scss";

//Assets
import DownArrow from "@vahak/core/dist/standard-icons/down_arrow.svg";

interface baseProps {
    children: ReactNode;
    title: string;
    className?: string;
    showLoadMore?: boolean;
    loadingState?: boolean;
}

interface loadMoreProps extends baseProps {
    showLoadMore: true;
    handleClickLoadMore: () => void;
}
interface ListContainerProps extends baseProps {
    showLoadMore?: false;
    handleClickLoadMore?: () => void;
}

type Props = loadMoreProps | ListContainerProps;

const ListContainer = ({ children, title, className, handleClickLoadMore, loadingState, ...props }: Props) => {
    const { showLoadMore } = props as loadMoreProps;
    return (
        <Flex flexDirection="column" className={classnames(Styles.main, className)}>
            <div className={Styles.title}>
                <Typography size="md" mSize="sm" weight="bold" as="h2">
                    {title}
                </Typography>
            </div>
            {children}

            {showLoadMore ? (
                <div className={Styles.loadMoreBtn}>
                    {!loadingState ? (
                        <Button
                            fillType="text"
                            onClick={handleClickLoadMore}
                            disabled={!!loadingState}
                            endIcon={<DownArrow />}
                            id={"Pagination_next"}
                        >
                            Load more
                        </Button>
                    ) : (
                        <DotLoader />
                    )}
                </div>
            ) : (
                <></>
            )}
        </Flex>
    );
};

export default ListContainer;
