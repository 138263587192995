import { ClientHookOptions, useVahakQuery } from "@vahak/core/dist/hooks/useVahakQueryClient";

import useVahakHttpClient from "@vahak/core/dist/hooks/useVahakHttpClient";
import API from "@vahak/core/dist/_services/const";
import { LoadMarketParam, LoadMarketType } from "./type";
import { generateUrlParamsFromArray } from "@vahak/core/dist/methods/generateUrlParamsFromArray";
import { generateUrlParamsFromObject } from "@vahak/core/dist/methods/generateUrlParamsFromObject";
import queryNames from "../../queryNames";

/**
 * Need to get rid of LoadMarketDetails (type)
 */

/**
 * Load market v2 api
 * @param param0
 * @param options
 * @returns
 */

export const useGetLoadMarketV2 = (
    {
        page,
        c_i,
        lorry_type_id,
        city_id,
        state_id,
        destination_city_id,
        destination_state_id,
        quantity_string,
        l,
        preloads,
        isAdmin,
        loadId,
        destination_place_id,
        source_place_id,
        tap_n_go_filter
    }: LoadMarketParam,
    options: ClientHookOptions<LoadMarketType, unknown, LoadMarketType["data"]>
) => {
    const { GET } = useVahakHttpClient();

    const payload = {
        page,
        c_i,
        lorry_type_id,
        city_id,
        state_id,
        destination_city_id,
        destination_state_id,
        quantity_string,
        destination_place_id,
        source_place_id,
        l,
        tap_n_go_filter
    };
    const urlString = generateUrlParamsFromObject(payload);
    let idString = loadId && generateUrlParamsFromArray(loadId, "id");
    let preloadsString =
        preloads &&
        generateUrlParamsFromArray(
            preloads,
            "preloads",
            !isAdmin ? (["agent_details"] as LoadMarketParam["preloads"]) : undefined
        );

    const getLoadMarketV2Data = () => {
        return GET<LoadMarketType>(
            `${API.LOAD_MARKET_V2}${urlString}${idString ? "&" + idString : ""}${
                preloadsString ? "&" + preloadsString : ""
            }`
        );
    };

    /**
     * If you don't know the error type pass unknown
     */
    return useVahakQuery<LoadMarketType, unknown, LoadMarketType["data"]>(
        [queryNames.getLoadMarketV2],
        getLoadMarketV2Data,
        {
            ...options,
            select: (data) => data.data
        }
    );
};
