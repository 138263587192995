import { ReactNode } from "react";
// Methods
import { getSchemaOrgJson } from "@vahak/core/dist/methods/getSchemaOrgJson";
import Head from "next/head";
import { getFaqSchemaOrgJson } from "../../../methods/getFaqSchemaOrgJson";

export interface PageMetaTagsProps {
    cityName?: string;
    title: string;
    description: string;
    keywords?: string;
    canonicalUrl: string;
    ogUrl: string;
    ogImgSrc?: string;
    ogImgAltText?: string;
    schemaOrgJsonUrl?: string;
    shouldBeIndexed?: boolean;
    breadcrumbList?: string;
    children?: ReactNode;
    faqList?: { question: string; answer: string }[];
}

/**
 * TODO: (Gowdham) Need to refactor this whole component to make use of companyDetails.ts constant file for default value
 */
const PageMetaTags = ({
    cityName,
    title = `Vahak - Book Best Transport Services in ${cityName}`,
    description = `Book 10,000+ loads and lorries in ${cityName}! Vahak is the #1 platform for ${cityName} transport services. Join India's largest transporter network today for free.`,
    keywords = `Book 10,000+ loads and lorries in ${cityName}! Vahak is the #1 platform for ${cityName} transport services. Join India's largest transporter network today for free.`,
    canonicalUrl = `https://www.vahak.in/city/${cityName}`,
    ogUrl = `https://www.vahak.in/city/${cityName}`,
    ogImgSrc = `https://d1rgemtytdlz2g.cloudfront.net/vahak-timeline_gvlxfa.png`,
    ogImgAltText = `Book 10,000+ loads and lorries in ${cityName}! Vahak is the #1 platform for ${cityName} transport services. Join India's largest transporter network today for free.`,
    schemaOrgJsonUrl = `/city/${cityName}`,
    shouldBeIndexed = true,
    breadcrumbList,
    children,
    faqList
}: PageMetaTagsProps) => {
    return (
        <Head>
            <title>{title}</title>
            <meta name="description" content={description} />
            <meta name="keywords" content={keywords} />
            <meta name="robots" content={shouldBeIndexed ? "index, follow" : "noindex, nofollow"} />
            <link rel="canonical" href={canonicalUrl} />
            <meta property="og:url" content={ogUrl} />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta property="og:image" content={ogImgSrc} />
            <meta property="og:image:alt" content={ogImgAltText} />
            <script
                type="application/ld+json"
                id="jsonld"
                dangerouslySetInnerHTML={{
                    __html: getSchemaOrgJson({
                        name: title,
                        description: description,
                        path: schemaOrgJsonUrl,
                        breadcrumbList: breadcrumbList
                    })
                }}
            />
            {faqList?.length && (
                <script
                    type="application/ld+json"
                    id="jsonld"
                    dangerouslySetInnerHTML={{
                        __html: getFaqSchemaOrgJson({
                            quesAns: faqList
                        })
                    }}
                />
            )}
            {children}
        </Head>
    );
};

export default PageMetaTags;
