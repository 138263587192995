import React, { ReactNode } from "react";
import classnames from "classnames";

// Style
import Styles from "./FieldWrapper.module.scss";

interface FieldWrapperProps {
    children: ReactNode;
    className?: string;
    space?: "md" | "lg";
}
const mapSpaceWithClassName = {
    md: Styles.medium,
    lg: Styles.large
};
const FieldWrapper = ({ children, className, space = "md" }: FieldWrapperProps) => {
    return <div className={classnames(Styles.main, mapSpaceWithClassName[space], className)}>{children}</div>;
};

export default FieldWrapper;
