// constants
import { vahakBenefits } from "./vahakBenefitsConstants";

// components
import GenericTitleDescriptionWithParas from "../../components/generic-title-description-with-paras/GenericTitleDescriptionWithParas";
import { useTranslation } from "next-i18next";
import Styles from "./VahakBenefits.module.scss";

interface VahakBenefitsProps {
    city: string;
    activeMarket: string;
}
const VahakBenefits = ({ city, activeMarket }: VahakBenefitsProps) => {
    const { t } = useTranslation();
    return (
        <div className={Styles.main}>
            <GenericTitleDescriptionWithParas
                // paragraphList={vahakBenefitsIntroParas}
                titleDescriptionList={vahakBenefits(city, activeMarket)}
                heading={`Benefits of ${activeMarket} Booking with Vahak ${city ? "in " + city : ""}`}
            />
        </div>
    );
};

export default VahakBenefits;
