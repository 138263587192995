import { ClientHookOptions, useVahakQuery } from "@vahak/core/dist/hooks/useVahakQueryClient";

import API from "@vahak/core/dist/_services/const";
import { QueryNames } from "@vahak/core/dist/_services/hooks/queryNames";

import useVahakHttpClient from "@vahak/core/dist/hooks/useVahakHttpClient";
import { generateQueryString } from "@vahak/core/dist/methods/generateQueryString";
import { ApiErrorResponseType } from "@vahak/core/dist/custom-types/api-error-response-type";

export interface GetPopularLaneResponse {
    data?: {
        load_sources_destination?: PopularSrcDest;
        lorry_source_destination?: PopularSrcDest;
    };
}
export interface PopularSrcDest {
    source_cities?: PopularLaneCityInfo[];
    destination_cities?: PopularLaneCityInfo[];
}

export interface PopularLaneCityInfo {
    city_id?: number;
    city_name?: string;
    city_data?: CityData[];
}

interface CityData {
    city_name?: string;
    place_id?: string;
    count?: number;
    city_id?: number;
    state_id?: number;
}

interface useGetPopularLaneRequest {
    preloads?: ("load_destinations" | "lorry_destinations" | "load_sources" | "lorry_sources")[];
    placeId: string;
}

export const useGetPopularLane = (
    params: useGetPopularLaneRequest,
    options: ClientHookOptions<GetPopularLaneResponse, unknown, GetPopularLaneResponse["data"]>
) => {
    const { placeId, preloads = [] } = params;
    const { GET } = useVahakHttpClient();

    const method = () => {
        const payload = {
            place_id: placeId,
            preloads
        };

        const urlString = generateQueryString(payload);
        return GET<GetPopularLaneResponse>(`${API.POPULAR_LANE_FOR_CITY}${urlString}`);
    };

    return useVahakQuery<GetPopularLaneResponse, unknown, GetPopularLaneResponse["data"]>(
        [QueryNames.popularLane, params],
        method,
        {
            ...options,
            select: (response) => response?.data
        }
    );
};

export const useGetMostUsedLane = (
    params: Partial<useGetPopularLaneRequest>,
    options: ClientHookOptions<GetPopularLaneResponse, ApiErrorResponseType, GetPopularLaneResponse["data"]>
) => {
    const { preloads = [] } = params;
    const { GET } = useVahakHttpClient();

    const method = () => {
        const payload = {
            country_id: 1,
            preloads
        };

        const urlString = generateQueryString(payload);

        return GET<GetPopularLaneResponse>(`${API.POPULAR_LANE_FOR_COUNTRY}${urlString}`);
    };

    return useVahakQuery<GetPopularLaneResponse, ApiErrorResponseType, GetPopularLaneResponse["data"]>(
        [QueryNames.popularLane, params],
        method,
        {
            ...options,
            select: (response) => response?.data
        }
    );
};
