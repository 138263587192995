import React, { useContext, useRef, useState } from "react";
import Styles from "./FullScreenLocationInput.module.scss";

// Components
import Flex from "@vahak/core-ui/dist/layout/Flex";
import Typography from "@vahak/core-ui/dist/components/Typography";
import ModalOrBottomSheetWrapper from "@vahak/core-ui/dist/components/ModalOrBottomSheetWrapper";
import IconWrapper from "@vahak/core-ui/dist/components/IconWrapper";
import Search, { SearchRef } from "@vahak/core-ui/dist/components/Search";
import Button from "@vahak/core-ui/dist/components/Button";
import Chip from "@vahak/core-ui/dist/components/Chip";
import AppImg from "../../../../utils/AppImg";
// Constants
import { AppContext } from "@vahak/core/dist/app-context";
import { PlaceSearchResult } from "@vahak/core/dist/custom-types";
import {
    GA4EventNames,
    getLorryDetailsByType,
    MarketPlaceType,
    NEW_LORRY_TYPES,
    NoSuccessErrorHandler
} from "@vahak/core/dist/constants";
import { MarketFilters } from "../../type";
import Suggestion from "./suggestion/Suggestion";
import COLORS from "@vahak/core-ui/dist/constants/colors";
// Methods
import { generateFieldName } from "../../../../../methods/form/generateFieldName";
import { generateSelectOption } from "../../../../../methods/uiUtil";
import { mapPlaceSearchResponseIntoText } from "../../../../posting-form/common/current-location/UserCurrentLocation";
// Hooks
import { usePlaceSearchQuery } from "../../../../../services/usePlaceSearchQuery";
import { LocationSearchSource } from "@vahak/core/dist/_services/city-search.service";
// Assets
import LoadWhite from "@vahak/core/dist/standard-icons/load-white.svg";
import LorryWhite from "@vahak/core/dist/standard-icons/lorry-white.svg";
import LocationSrcIcon from "@vahak/core/dist/icons/GreenCircle.svg";
import LocationDestIcon from "@vahak/core/dist/icons/LocationPinRed2.svg";
import BackBtn from "@vahak/core/dist/icons/back_arrow_dark.svg";
import MapPinGrey from "@vahak/core/dist/icons/LocationPinWithBg.svg";
import CurrentLocationIcon from "@vahak/core/dist/icons/location_blue_circle.svg";
import AnyDestinationIcon from "@vahak/core/dist/icons/any_destination.svg";
import HomeLocationIcon from "@vahak/core/dist/icons/home_location.svg";
import { toggleArrayValue } from "@vahak/core-ui/dist/methods";
import { LoadAggregationResponse } from "../../../../../services/load/useGetLoadMarketV2/type";
import { IEventTrackerProp } from "../../../../../types/events";

//

export interface FullScreenLocationInputProps extends IEventTrackerProp {
    companyId?: number;
    marketType?: MarketPlaceType;
    filters: MarketFilters | undefined;
    onApplyFilters: React.Dispatch<React.SetStateAction<MarketFilters | undefined>>;
    onClearFilter?: () => void;
    onSubmit?: () => void;
    titleText?: string;
    onToggle?: () => void;
    activeField: FullScreenLocationInputField;
    lorryCount?: LoadAggregationResponse["lorry_type_id"];
}

const defaultQueriesValue = {
    src: "",
    dest: ""
};

const formFieldName = generateFieldName(defaultQueriesValue);
export type FullScreenLocationInputField = keyof typeof formFieldName;

const FullScreenLocationInput = ({
    onToggle,
    titleText = "Search",
    filters,
    onApplyFilters,
    marketType,
    companyId,
    onClearFilter,
    onSubmit,
    activeField,
    lorryCount,
    trackEvent
}: FullScreenLocationInputProps) => {
    const { globalData, userCompanyInfo } = useContext(AppContext);
    const currentLocation = globalData?.userPlaceInfo as PlaceSearchResult;

    const srcRef = useRef<SearchRef>(null);
    const destRef = useRef<SearchRef>(null);

    const [queryString, setQueryString] = useState("");
    const [activeInputFieldName, setActiveInputFieldName] = useState<FullScreenLocationInputField>();
    const [locationResultList, setLocationResultList] = useState<PlaceSearchResult[]>();

    const isLoadMkt = marketType === MarketPlaceType.LOAD;
    const isLorryMkt = marketType === MarketPlaceType.LORRY;

    const placeSearchApiResponse = usePlaceSearchQuery(
        {
            query: queryString,
            option: {
                source: LocationSearchSource.LoadMarketPlaceSearchSource
            }
        },
        {
            ...NoSuccessErrorHandler,
            onSuccess: setLocationResultList
        }
    );

    const focusInputByTargetType = (target: FullScreenLocationInputField) => {
        target === formFieldName.src && srcRef?.current?.focusInput();
        target === formFieldName.dest && destRef?.current?.focusInput();
    };

    const removeInputFocus = () => {
        setActiveInputFieldName(undefined);
    };

    const onSelect = (target: FullScreenLocationInputField, place: PlaceSearchResult | undefined) => {
        onApplyFilters({
            ...filters,
            ...(target === formFieldName.src && {
                srcPlace: place,
                srcText: mapPlaceSearchResponseIntoText(place, 1)
            }),
            ...(target === formFieldName.dest && {
                destPlace: place,
                destText: mapPlaceSearchResponseIntoText(place, 1)
            })
        });

        !!place && setActiveInputFieldName(undefined);
        setLocationResultList(undefined);
        target === formFieldName.src && place && !filters?.destText && focusInputByTargetType(formFieldName.dest);
        target === formFieldName.dest && place && !filters?.srcText && focusInputByTargetType(formFieldName.src);
    };

    const srcOptionValue = !!filters?.srcText ? generateSelectOption(filters?.srcText, "") : null;
    const destOptionValue = !!filters?.destText ? generateSelectOption(filters?.destText, "") : null;

    const common = {
        option: [],
        menuIsOpen: false,
        customDropDownIcon: () => null,
        onInputChange: setQueryString,
        isClearable: true,
        loadOptions: (str: string, cb: Function) => cb([]),
        backspaceRemovesValue: false
    };

    const isBothLocationSelected = !!filters?.destText && !!filters?.srcText;
    const isAnyLocationSelected = !!filters?.destText || !!filters?.srcText;

    const QuickSelectLocation = !!activeInputFieldName && (
        <div className={Styles.quickAction}>
            {!!currentLocation?.place_id && (
                <Chip
                    filled
                    fillColor={"transparent"}
                    size="auto"
                    color="blue"
                    onClick={() => {
                        onSelect(activeInputFieldName, currentLocation);
                    }}
                >
                    <CurrentLocationIcon />
                    Current Location
                </Chip>
            )}

            {!!userCompanyInfo?.placeId && (
                <Chip
                    filled
                    fillColor={"transparent"}
                    size="auto"
                    color="blue"
                    onClick={() => {
                        onSelect(activeInputFieldName, {
                            city: userCompanyInfo?.cityName,
                            place_id: userCompanyInfo?.placeId,
                            state: userCompanyInfo?.stateName
                        });
                    }}
                >
                    <HomeLocationIcon />
                    Home City
                </Chip>
            )}

            {activeInputFieldName === formFieldName.dest && (
                <Chip
                    filled
                    fillColor={"transparent"}
                    size="auto"
                    color="blue"
                    onClick={() => {
                        activeInputFieldName &&
                            onSelect(activeInputFieldName, {
                                city: "Any destination"
                            });
                    }}
                >
                    <AnyDestinationIcon />
                    Any Destination
                </Chip>
            )}
        </div>
    );

    return (
        <ModalOrBottomSheetWrapper isOpen fullScreenSheet renderTitle={<></>} paddedSheet={false}>
            <div className={Styles.layout}>
                <div className={Styles.header}>
                    <IconWrapper onClick={onToggle}>
                        <BackBtn />
                    </IconWrapper>
                    <Typography weight="medium" size="md">
                        {titleText}
                    </Typography>
                </div>
                <div className={Styles.main}>
                    <div className={Styles.locations}>
                        <div className={Styles.location}>
                            <LocationSrcIcon />
                            <Search
                                {...common}
                                name={formFieldName.src}
                                value={srcOptionValue}
                                onFocus={setActiveInputFieldName.bind(undefined, formFieldName.src)}
                                onChange={(option: any) => {
                                    trackEvent?.(GA4EventNames.MARKET_PLACE["marketplace_source_city_entered"]);
                                    onSelect(formFieldName.src, option);
                                }}
                                ref={srcRef}
                                autoFocus={activeField === formFieldName.src}
                                {...(activeInputFieldName === formFieldName.src && {
                                    isLoading: placeSearchApiResponse?.isLoading
                                })}
                            />
                        </div>
                        <div className={Styles.location}>
                            <LocationDestIcon />
                            <Search
                                {...common}
                                name={formFieldName.dest}
                                value={destOptionValue}
                                onFocus={setActiveInputFieldName.bind(undefined, formFieldName.dest)}
                                onChange={(option: any) => {
                                    trackEvent?.(GA4EventNames.MARKET_PLACE["marketplace_destination_city_entered"]);
                                    onSelect(formFieldName.dest, option);
                                }}
                                ref={destRef}
                                autoFocus={activeField === formFieldName.dest}
                                {...(activeInputFieldName === formFieldName.dest && {
                                    isLoading: placeSearchApiResponse?.isLoading
                                })}
                            />
                        </div>
                    </div>

                    {!!locationResultList?.length && !!activeInputFieldName && (
                        <div className={Styles.list}>
                            {locationResultList?.map((loc) => {
                                return (
                                    <div
                                        key={loc?.place_id}
                                        className={Styles.item}
                                        onClick={onSelect.bind(undefined, activeInputFieldName, loc)}
                                    >
                                        <MapPinGrey />
                                        <div className={Styles.desc}>
                                            <Typography size="sm">{loc?.description}</Typography>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    )}

                    {!!activeInputFieldName && !queryString && (
                        <>
                            <>{QuickSelectLocation}</>
                            {!filters?.srcPlace || !filters?.destPlace ? (
                                <Suggestion
                                    companyId={companyId}
                                    marketType={marketType}
                                    filters={filters}
                                    onApplyFilters={onApplyFilters}
                                    showSrcLaneSuggestion={activeInputFieldName === formFieldName.src}
                                    showDestLaneSuggestion={activeInputFieldName === formFieldName.dest}
                                    shiftInputFocus={focusInputByTargetType}
                                    removeInputFocus={removeInputFocus}
                                />
                            ) : null}
                        </>
                    )}
                    {!!isBothLocationSelected && (
                        <div className={Styles.lorryTypeSelection}>
                            {NEW_LORRY_TYPES.map((lorry) => {
                                const count = lorryCount?.find((l) => l.lorry_type_id === lorry?.id)?.count;
                                const isActive = filters?.lorryTypeIds?.includes(lorry?.id);
                                return (
                                    <Chip
                                        key={lorry?.id}
                                        radius={12}
                                        size="auto"
                                        outlined
                                        outlineColor={COLORS.GREY_200}
                                        filled={false}
                                        {...(isActive && {
                                            filled: true,
                                            fillColor: COLORS.BLUE_100,
                                            outlineColor: COLORS.BLUE,
                                            fontColor: COLORS.BLUE
                                        })}
                                    >
                                        <Flex
                                            padding={"12px"}
                                            flex={1}
                                            alignItems="center"
                                            justifyContent="center"
                                            flexDirection="column"
                                            gap={4}
                                            onClick={() => {
                                                const lorryTypeIds = toggleArrayValue(
                                                    filters?.lorryTypeIds ?? [],
                                                    lorry?.id
                                                );
                                                trackEvent?.(GA4EventNames.MARKET_PLACE["filters_clicked"], {
                                                    filter_selection: lorryTypeIds?.map(
                                                        (lr) => getLorryDetailsByType(lr)?.label
                                                    )
                                                });
                                                onApplyFilters({
                                                    ...filters,
                                                    lorryTypeIds: lorryTypeIds
                                                });
                                            }}
                                        >
                                            <AppImg
                                                src={`/${lorry?.fullIconRight}`}
                                                width={100}
                                                height={36}
                                                unoptimized
                                            />
                                            <Typography>
                                                {lorry?.label}
                                                {!!count ? ` (${count})` : "   "}
                                            </Typography>
                                        </Flex>
                                    </Chip>
                                );
                            })}
                        </div>
                    )}
                </div>
                {isAnyLocationSelected && (
                    <div className={Styles.footer}>
                        <Button fillType="outlined" onClick={onClearFilter}>
                            Clear all
                        </Button>
                        <Button blockBtn onClick={onSubmit}>
                            {isLoadMkt && (
                                <IconWrapper gap={4}>
                                    <LoadWhite />
                                    Find Load
                                </IconWrapper>
                            )}
                            {isLorryMkt && (
                                <IconWrapper gap={4}>
                                    <LorryWhite />
                                    Find Lorry
                                </IconWrapper>
                            )}
                        </Button>
                    </div>
                )}
            </div>
        </ModalOrBottomSheetWrapper>
    );
};

export default FullScreenLocationInput;
