// types
import Typography from "@vahak/core-ui/dist/components/Typography";
import { TitleDescriptionItemType } from "../types";

// styles
import Styles from "./TitleDescriptionItem.module.scss";
import { sanitize } from "isomorphic-dompurify";
import COLORS from "@vahak/core-ui/dist/constants/colors";

interface TitleDescriptionItemProps {
    item: TitleDescriptionItemType;
}

const TitleDescriptionItem = ({ item }: TitleDescriptionItemProps) => {
    return (
        <div className={Styles.titleDescriptionItem}>
            <Typography as="h3" size="s" mSize="xs" color={COLORS.GREY_700}>
                {item.title ? <>{item.title} &nbsp;</> : "\u2022"}
            </Typography>
            <Typography as="span" size="s" mSize="xs" color={COLORS.GREY_700} lineHeight="1.5rem">
                <span dangerouslySetInnerHTML={{ __html: sanitize(item.description) }} />
            </Typography>
        </div>
    );
};

export default TitleDescriptionItem;
