import { ParagraphType, TitleDescriptionItemType } from "../generic-title-description-with-paras/types";

export const vahakBenefitsIntroParas: ParagraphType[] = [
    {
        id: 0,
        content:
            "Vahak is India’s largest and most trusted online transport services company. We at Vahak are committed to improving the Indian transportation services industry through a technology-driven approach. Through our platform, we are helping reduce the current demand-supply gap in the transport industry by providing instant online load and <a href='/online-truck-booking'>truck booking</a> services at 0% commission."
    },
    {
        id: 1,
        content:
            "Vahak hosts India’s largest <a href='/marketplace/load'>online transport marketplace</a>, allowing users to book transport services instantly without paying commission charges. Through the Vahak Transport Marketplace, users can bid & book loads or lorries from across India. With more than eight lakh verified trucks and 20,000+ daily active loads listed on Vahak,  users can pick the best deal in accordance  with  their needs."
    }
];

export const vahakBenefits = (city: string, activeMarket: string): TitleDescriptionItemType[] => {
    const inCityText = city ? `in ${city}` : "";
    return [
        {
            id: 0,
            title: `Connect with ${city} Largest Online Transport Community`,
            description: `Vahak users get access to ${city} largest transport community. Users can connect with over 20 Lakh transporters pan India and find suitable ${activeMarket} transport service provider basis their needs.`
        },
        {
            id: 1,
            title: `Verified Transporters ${inCityText}`,
            description: `All the transporters and transport service providers listed on the Vahak platform ${inCityText} are KYC & GST-verified. This helps reduce fraudulent activities and thus offers a hassle-free booking experience.`
        },
        {
            id: 2,
            title: `Book from ${inCityText} Largest Online Transport Marketplace`,
            description: `Vahak hosts ${activeMarket} India’s largest online transport marketplace through which users can connect with transporters/shippers/transport agents from across India and find the best deal at the best prices with a variety of options.`
        },
        {
            id: 3,
            title: `Verified Trucks & thousands of Daily Active Loads ${inCityText}`,
            description:
                "With over 8 lakh verified trucks  and over 20,000 daily active loads on Vahak, users are guaranteed instant online truck and load booking at the best prices. Truck owners can also enjoy quick availability of return loads on their preferred routes without any hassles."
        },
        {
            id: 4,
            title: "100% Safe & Secure Payment Gateway",
            description:
                "Vahak Payment gateway allows users to make or receive instant payments for their bookings made on Vahak. The RBI-approved payment gateway is 100% safe and secure and aids online bookings without hassles or delays."
        },
        {
            id: 5,
            title: "Earn up to 4% Cashbacks",
            description: `Booking ${activeMarket} transport services on Vahak ${inCityText} not only helps users with operational benefits but also ensures that they make profits on every bookings. With cashback offers, users can  earn up to 4% cashback on their online ${activeMarket} bookings and increase their per trip income.`
        },
        {
            id: 6,
            title: "24x7 Customer Support",
            description:
                "Vahak offers 24x7 customer support to help its users with booking-related issues or general queries."
        }
    ];
};
