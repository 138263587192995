import { ClientHookInfiniteQueryOptions, useVahakInfiniteQuery } from "@vahak/core/dist/hooks/useVahakQueryClient";

import useVahakHttpClient from "@vahak/core/dist/hooks/useVahakHttpClient";
import API from "@vahak/core/dist/_services/const";
import queryNames from "../../queryNames";
import { LorryMarketParam, LorryMarketType } from "./type";
import { generateQueryString } from "@vahak/core/dist/methods";

export const useGetLorryMarketInfiniteQueryV2 = (
    { page, ...params }: LorryMarketParam,
    options: ClientHookInfiniteQueryOptions<LorryMarketType, unknown, LorryMarketType["data"]>
) => {
    const { GET } = useVahakHttpClient();

    function getLoads({ pageParam = 1 }) {
        const payload = {
            ...params,
            page: pageParam
        };
        const urlString = generateQueryString(payload);

        return GET<LorryMarketType>(`${API.LORRY_MARKET_V2}${urlString}`);
    }

    return useVahakInfiniteQuery<LorryMarketType, unknown>(
        [queryNames.getLorryMarketInfiniteQueryV2, params],
        getLoads,
        {
            getNextPageParam: (lastPage, allPages) => {
                if (lastPage.data?.page_number < lastPage.data?.total_pages) return lastPage.data?.page_number + 1;
                return undefined;
            },
            ...options,
            onSuccess: (data) => {
                let aggregatedData: LorryMarketType["data"] = {
                    page_number: 0,
                    total_pages: 0,
                    total_records: 0,
                    ...data?.pages?.[0],
                    lorry_cards: data?.pages?.flatMap((p) => p?.data?.lorry_cards ?? [])
                };

                /**
                 * Enclosing aggregatedData inside an array, just to fix the type script
                 * as the onSuccess returns only aggregatedData[] - array type
                 */
                options.onSuccess([aggregatedData]);
            },
            refetchOnWindowFocus: false
        }
    );
};
