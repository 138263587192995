import React from "react";
import Styles from "./RecentRoute.module.scss";
import IconWrapper from "@vahak/core-ui/dist/components/IconWrapper";
import LorryIcon from "@vahak/core/dist/icons/lorry-icon-grey.svg";
import RightArrowIcon from "@vahak/core/dist/icons/arrow_right.svg";
import { DotSymbol } from "@vahak/core-ui/dist/constants/symbol";
import { LORRY_CATEGORIES_ENUM, getLorryDetailsByType } from "@vahak/core/dist/constants/lorryTypes";
import { filteredArray } from "@vahak/core-ui/dist/methods/array";

interface RecentRouteProps {
    id?: string;
    src?: string;
    dest?: string;
    lorryType?: LORRY_CATEGORIES_ENUM;
    lorryCapacity?: number;
    materialName?: string;
    lorriesAvailable?: number;
    onClick?: () => void;
    showArrow?: boolean;
    showBulletsDesc?: boolean;
    showSearchedLorryType?: boolean;
}

const RecentRoute = ({
    id = "recent",
    dest = "",
    src = "",
    materialName = undefined,
    lorriesAvailable = 0,
    lorryCapacity = 0,
    lorryType,
    onClick,
    showArrow = true,
    showBulletsDesc = true,
    showSearchedLorryType = false
}: RecentRouteProps) => {
    const { label, unit } = getLorryDetailsByType(lorryType!) ?? {};
    const lorryDesc = label && unit ? `${lorryCapacity} ${unit}` : null;
    const bullets = filteredArray([label, lorryDesc, materialName]);

    return (
        <div className={Styles.main} onClick={onClick} id={id}>
            <div className={Styles.info}>
                <div className={Styles.routes}>
                    {!!src && (
                        <span className={Styles.route} id={id + "-src"}>
                            {src}
                        </span>
                    )}
                    {!!src && !!dest && (
                        <IconWrapper width={8} height={8}>
                            <RightArrowIcon />
                        </IconWrapper>
                    )}
                    {dest && (
                        <span className={Styles.route} id={id + "-dest"}>
                            {dest}
                        </span>
                    )}
                </div>
                {!!showBulletsDesc && !!bullets?.length && (
                    <div className={Styles.bullets}>{bullets.join(` ${DotSymbol} `)}</div>
                )}
                {!!showSearchedLorryType && <div className={Styles.desc}>Vehicle Type: {label ?? "NA"}</div>}
                {!!lorriesAvailable && (
                    <div className={Styles.availability}>
                        <LorryIcon /> {lorriesAvailable} lorries available
                    </div>
                )}
            </div>
            {showArrow && (
                <div className={Styles.action}>
                    <IconWrapper width={14} height={14}>
                        <RightArrowIcon />
                    </IconWrapper>
                </div>
            )}
        </div>
    );
};

export default RecentRoute;
