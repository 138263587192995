import API from "@vahak/core/dist/_services/const";
import { ClientHookInfiniteQueryOptions, useVahakInfiniteQuery } from "@vahak/core/dist/hooks/useVahakQueryClient";
import useVahakHttpClient from "@vahak/core/dist/hooks/useVahakHttpClient";

/**
 * Get All tagged users of tagged TSO with filters
 */
interface GetLorryEnquiryCardParams {
    preloads?: "city_details";
    companyId: number;
}

export interface LorryEnquiryType {
    lorry_type_id?: number[];
    source_city_id: number[];
    source_state_id: number[];
    destination_city_id: number[];
    destination_state_id: number[];
    id: string;
    favourite: boolean;
    company_id: number;
    match_count: number;
    dirty: boolean;
}
export interface AggregatedLorryEnquiryType {
    company_id: number;
    destination_city_id: number;
    destination_city_name: string;
    destination_place_id: string;
    destination_state_id: number;
    destination_state_name: string;
    dirty: boolean;
    favourite: boolean;
    id: string;
    match_count: number;
    source_city_id: number;
    source_city_name: string;
    source_place_id: string;
    source_state_id: number;
    source_state_name: string;
    lorry_type_id?: Array<number>;
}

export interface GetLorryEnquiryCardListResponse {
    data: {
        filter: LorryEnquiryType[];
        load_filter: {}[];
        lorry_filter: LorryEnquiryType[];
        total_pages: number;
        total_records: number;
        page_number: number;
        preloads: {
            city_details: [
                {
                    city_place_id: string;
                    city_id: number;
                    city_name: string;
                    state_id: number;
                    state_name: string;
                }
            ];
        };
    };
}

export const useGetLorryEnquiryCardDetails = (
    params: GetLorryEnquiryCardParams,
    options: ClientHookInfiniteQueryOptions<GetLorryEnquiryCardListResponse, unknown, AggregatedLorryEnquiryType>
) => {
    const { GET } = useVahakHttpClient();

    function getCSUsers({ pageParam = 1 }) {
        const { companyId, preloads } = params;
        return GET<GetLorryEnquiryCardListResponse>(
            `${API.LORRY_ENQUIRY_CARD}?c_i=${companyId}&preloads=city_details&`,
            {
                page: pageParam
            }
        );
    }

    return useVahakInfiniteQuery<GetLorryEnquiryCardListResponse, unknown>(["lorry-enquiry-card"], getCSUsers, {
        getNextPageParam: (lastPage, allPages) => {
            if (lastPage.data?.page_number < lastPage.data?.total_pages) return lastPage.data?.page_number + 1;
            return undefined;
        },
        ...options,
        onSuccess: (data) => {
            let aggregatedData: AggregatedLorryEnquiryType[] = [];
            if (data.pages[0].data.lorry_filter.length) {
                const cityDetailsArr = data.pages[0].data.preloads.city_details;
                const lorryFilterDetailsArr = data.pages[0].data.lorry_filter;

                const cityDetailsMap: {
                    city_id: number;
                    city_name: string;
                    state_id: number;
                    state_name: string;
                    place_id: string;
                }[] = [];
                cityDetailsArr?.forEach((city) => {
                    cityDetailsMap[city.city_id] = {
                        city_id: city.city_id,
                        city_name: city.city_name,
                        state_id: city.state_id,
                        state_name: city.state_name,
                        place_id: city.city_place_id
                    };
                });

                // Iterate through LorryDetails and generate aggregated data
                const aggregatedDataNew = lorryFilterDetailsArr.map((lorry) => {
                    const sourceCityId = lorry.source_city_id[0];
                    const destinationCityId = lorry.destination_city_id[0];
                    return {
                        id: lorry.id,
                        favourite: lorry.favourite,
                        company_id: lorry.company_id,
                        match_count: lorry.match_count,
                        dirty: lorry.dirty,
                        source_place_id: cityDetailsMap[sourceCityId].place_id,
                        source_city_id: sourceCityId,
                        source_city_name: cityDetailsMap[sourceCityId].city_name,
                        source_state_id: cityDetailsMap[sourceCityId].state_id,
                        source_state_name: cityDetailsMap[sourceCityId].state_name,
                        destination_place_id: cityDetailsMap[destinationCityId].place_id,
                        destination_city_id: destinationCityId,
                        destination_city_name: cityDetailsMap[destinationCityId].city_name,
                        destination_state_id: cityDetailsMap[destinationCityId].state_id,
                        destination_state_name: cityDetailsMap[destinationCityId].state_name,
                        lorry_type_id: lorry.lorry_type_id
                    };
                });

                aggregatedData = aggregatedDataNew;
            }
            options.onSuccess(aggregatedData);
        }
    });
};
