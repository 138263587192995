import React, { ReactNode } from "react";
import classnames from "classnames";

// Style
import Styles from "./FieldHeading.module.scss";

interface FieldHeadingProps {
    children: ReactNode;
    className?: string;
}
const FieldHeading = ({ children, className }: FieldHeadingProps) => {
    return <div className={classnames(Styles.FieldHeading, className)}>{children}</div>;
};

export default FieldHeading;
